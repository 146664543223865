import React, { Key, useState } from 'react';
import FaAngleDown from 'react-icons/lib/fa/angle-down';
import OutsideClickHandler from 'react-outside-click-handler';
import { useMediaQuery } from 'react-responsive';

import { BREAKPOINT_DESKTOP_START } from 'constants/breakpoints';
import { Drawer } from 'modules/common-ui';

import { Portal } from '@mui/material';
import { usePopper } from 'react-popper';
import {
  Container,
  Ellipsis,
  Label,
  Left,
  Option,
  Options,
  Right,
  Select,
} from './index.css';

export type DropdownOption<ValueType = any, ExtraParams = unknown> = {
  dataFor?: string | false;
  tooltip?: JSX.Element | false;
  label: string;
  value: ValueType;
  disabled?: boolean;
  selected?: boolean;
  variant?: 'default' | 'danger';
  renderOption?: (
    option: DropdownOption<ValueType, ExtraParams>,
  ) => JSX.Element;
} & ExtraParams;

export type DropdownProps<ValueType = any, ExtraParams = unknown> = {
  options: DropdownOption<ValueType, ExtraParams>[];
  value: ValueType | null;
  className?: string;
  placeholder?: string;
  onChange: (value: ValueType) => void;
  light?: boolean;
  selectStyle?: React.CSSProperties;
  menuWidth?: number;
  menuStyle?: React.CSSProperties;
  align?: 'left' | 'right';
  inputType?: boolean;
  ellipsis?: boolean;
  header?: string;
  useRenderOptionForSelect?: boolean;
  disabled?: boolean;
  children?: React.ReactNode;
  usePortal?: boolean;
  portalContainerId?: string;
  customSelector?: React.ReactNode;
  forwardRef?: React.RefObject<HTMLDivElement>;
  openTop?: boolean;
};

// eslint-disable-next-line max-statements
export const Dropdown = <
  ValueType extends Key,
  ExtraParams extends Object = {},
>({
  options,
  value,
  className,
  placeholder = '',
  onChange,
  light = false,
  selectStyle = {},
  menuWidth,
  menuStyle = {},
  align = 'left',
  inputType = false,
  ellipsis = false,
  header = '',
  useRenderOptionForSelect = false,
  disabled,
  children,
  usePortal = false,
  portalContainerId,
  customSelector,
  forwardRef,
  openTop,
}: DropdownProps<ValueType, ExtraParams>) => {
  const isMobile = useMediaQuery({ maxWidth: BREAKPOINT_DESKTOP_START });

  const [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null,
  );
  const { styles, attributes } = usePopper(referenceElement, popperElement);

  const [display, setDisplay] = useState(false);

  let text = placeholder;
  let renderedOption: JSX.Element | undefined = undefined;

  if (value !== null && value !== undefined) {
    const idx = options.findIndex((o) => o.value === value);
    if (idx > -1) {
      if (options[idx].renderOption) {
        renderedOption = options[idx].renderOption!(options[idx]);
      }
      text = options[idx].label;
    }
  }

  const renderSelect = () => {
    const selected = (
      <Left>
        {ellipsis ? (
          <Ellipsis>{text}</Ellipsis>
        ) : useRenderOptionForSelect && renderedOption !== undefined ? (
          renderedOption
        ) : (
          text
        )}
      </Left>
    );

    const handleSelectClick = (e: React.MouseEvent) => {
      e.stopPropagation();
      setDisplay(!display);
    };

    const hasText = text && text.length > 0;
    return (
      <Select
        ref={setReferenceElement}
        light={light}
        className={className}
        onClick={disabled ? undefined : handleSelectClick}
        style={selectStyle}
        inputType={inputType}
        isFocused={display}
        disabled={disabled}
      >
        {hasText && selected}
        {customSelector ? (
          customSelector
        ) : (
          <Right>
            <FaAngleDown />
          </Right>
        )}
      </Select>
    );
  };

  if (isMobile) {
    return (
      <Container ref={forwardRef}>
        {renderSelect()}
        <Drawer
          headerStyle={{ marginBottom: '20px' }}
          isOpen={display}
          onDone={() => setDisplay(false)}
          title={header}
        >
          {options.map((o, index) => (
            <Option
              key={index.toString()}
              onClick={(e) => {
                e.stopPropagation();
                setDisplay(false);
                onChange(o.value);
              }}
            >
              {o.renderOption ? (
                o.renderOption(o)
              ) : (
                <Label variant={o.variant}> {o.label} </Label>
              )}
            </Option>
          ))}
        </Drawer>
      </Container>
    );
  }

  const renderDropdown = () => {
    if (!display) return null;
    return (
      <OutsideClickHandler
        onOutsideClick={() => {
          setDisplay(false);
        }}
      >
        <Options
          width={menuWidth}
          align={align}
          style={{
            ...menuStyle,
          }}
          openTop={openTop}
        >
          {options.map((o, index) => (
            <div key={index.toString()}>
              {o.tooltip}
              <Option
                data-for={o.dataFor}
                data-tip=""
                disabled={o.disabled}
                key={o.value}
                selected={o.selected}
                onClick={(e) => {
                  e.stopPropagation();
                  if (o.disabled) return;
                  setDisplay(false);
                  onChange(o.value);
                }}
                style={{ minHeight: !o.renderOption ? 28 : undefined }}
              >
                {o.renderOption ? (
                  o.renderOption(o)
                ) : (
                  <Label variant={o.variant}> {o.label} </Label>
                )}
              </Option>
            </div>
          ))}
          {children}
        </Options>
      </OutsideClickHandler>
    );
  };

  const renderPortalDropdown = () => {
    return (
      <Portal
        container={
          portalContainerId ? document.getElementById(portalContainerId) : null
        }
      >
        <div
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
        >
          {renderDropdown()}
        </div>
      </Portal>
    );
  };

  return (
    <Container ref={forwardRef}>
      {renderSelect()}
      {usePortal ? renderPortalDropdown() : renderDropdown()}
    </Container>
  );
};
