import colors from 'constants/colors';
import { CircleIcon } from 'modules/common-ui';
import { useTranslation } from 'react-i18next';
import { FaultCountBySeverity } from '../../../assets/alerts/types';
import {
  FaultCountItem,
  FaultsCountWrapper,
  FaultSeverity,
  IconWrapper,
} from './styles.css';

type CounterSize = 'small' | 'big';

export const AssetFaultsCounter: React.FC<
  Partial<FaultCountBySeverity & { size: CounterSize; assets: number }>
> = ({
  total,
  critical,
  high,
  medium,
  low,
  unknown,
  assets,
  size = 'small',
}) => {
  const [t] = useTranslation('alerts');

  const getStyles = (size: CounterSize) => {
    let numberStyle = {};
    let labelStyle = {};
    switch (size) {
      case 'small':
        numberStyle = { fontSize: '14px' };
        labelStyle = { fontSize: '11px' };
        break;
      case 'big':
        numberStyle = { fontSize: '20px', lineHeight: '22px' };
        labelStyle = { fontSize: '12px' };
    }
    return { numberStyle, labelStyle };
  };

  const renderBulletCount = (
    color: string,
    label: string,
    size: CounterSize,
    count?: number,
  ) => {
    if (count !== undefined && (size === 'big' || count > 0)) {
      const { numberStyle, labelStyle } = getStyles(size);
      return (
        <FaultCountItem>
          <IconWrapper style={numberStyle}>
            <CircleIcon color={color} fill={color} size={8} />
            {count}
          </IconWrapper>
          <FaultSeverity style={labelStyle}>{label}</FaultSeverity>
        </FaultCountItem>
      );
    }
    return null;
  };

  const renderCount = (label: string, size: CounterSize, count?: number) => {
    if (count !== undefined) {
      const { numberStyle, labelStyle } = getStyles(size);
      return (
        <FaultCountItem>
          <IconWrapper style={numberStyle}>{count}</IconWrapper>
          <FaultSeverity style={labelStyle}>{label}</FaultSeverity>
        </FaultCountItem>
      );
    }
    return null;
  };

  return (
    <FaultsCountWrapper>
      {renderCount(
        t('alerts.configuration.common.fields.assets.title'),
        size,
        assets,
      )}
      {renderCount(
        t('alerts.configuration.fault.fields.severity.counter'),
        size,
        total,
      )}
      {renderBulletCount(
        colors.red200,
        t('alerts.configuration.fault.fields.severity.options.critical'),
        size,
        critical,
      )}
      {renderBulletCount(
        colors.red100,
        t('alerts.configuration.fault.fields.severity.options.high'),
        size,
        high,
      )}
      {renderBulletCount(
        colors.orange100,
        t('alerts.configuration.fault.fields.severity.options.medium'),
        size,
        medium,
      )}
      {renderBulletCount(
        colors.purple100,
        t('alerts.configuration.fault.fields.severity.options.low'),
        size,
        low,
      )}
      {renderBulletCount(
        colors.neutral200,
        t('alerts.configuration.fault.fields.severity.options.unknown'),
        size,
        unknown,
      )}
    </FaultsCountWrapper>
  );
};
