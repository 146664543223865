import colors from 'constants/colors';
import styled from 'styled-components';

export const FaultsCountWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-bottom: 4px;
`;

export const FaultCountItem = styled.div`
  display: flex;
  flex-direction: column;
`;

export const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  font-weight: 600;
`;

export const FaultSeverity = styled.div`
  color: ${colors.gray500};
  text-transform: lowercase;
`;
